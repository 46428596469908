<template>
  <q-select
    v-bind="$attrs"
    v-on="$listeners"
    ref="autocomplete"
    :options="filteredOptions"
    :use-input="useInput"
    :option-value="optionValue"
    :option-label="optionLabel"
    :clearable="clearable"
    @input-value="onInputValue"
  >
    <template #no-option>
      <q-item>
        <q-item-section class="text-grey"> No results </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    useInput: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    optionValue: {
      type: String,
      default: 'value',
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filteredOptions() {
      const searchText = this.searchText.trim().toLowerCase();

      if (!searchText.length) {
        return this.options;
      }

      const optionLabel = this.optionLabel;

      return this.options.filter((option) => {
        const label = option[optionLabel];

        return label?.toLowerCase().includes(searchText);
      });
    },
  },
  data() {
    return {
      searchText: '',
    };
  },
  methods: {
    onInputValue(value) {
      this.searchText = value;
    },
    validate() {
      return this.$refs.autocomplete.validate();
    },
    focus() {
      return this.$refs.autocomplete.focus();
    },
  },
};
</script>
